import PropTypes from 'prop-types';

// eslint-disable-next-line no-restricted-imports
import { PUPPY_CARD_CTA_TYPES } from 'dpl/puppy_search_results/utils/constants';

export function asTptConnectPropTypes(propTypesDefinition) {
  return PropTypes.shape({
    meta: PropTypes.shape({
      isFetching: PropTypes.bool,
      isSuccess: PropTypes.bool,
      isError: PropTypes.bool,
      lastUpdated: PropTypes.number
    }),
    value: PropTypes.shape({
      data: propTypesDefinition
    }).isRequired
  });
}

export const MediaCategoryPropTypes = {
  id: PropTypes.number,
  slug: PropTypes.string,
  name: PropTypes.string,
  album_type: PropTypes.string,
  position: PropTypes.number
};

export const DogPropTypes = {
  id: PropTypes.number,
  name: PropTypes.string,
  profile_photo_url: PropTypes.string,
  cropped_url: PropTypes.string,
  gender: PropTypes.string,
  breed: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string
  })
};

export const PuppyPropTypes = {
  ...DogPropTypes,
  available: PropTypes.bool
};

export const TestimonialRequestPropTypes = PropTypes.shape({
  id: PropTypes.number,
  created_at: PropTypes.number,
  buyer_profile: PropTypes.shape({
    first_name: PropTypes.string
  })
});

export const DogEditPropTypes = {
  id: PropTypes.number,
  gender: PropTypes.string,
  name: PropTypes.string,
  registered_name: PropTypes.string,
  description: PropTypes.string,
  profile_photo_attributes: PropTypes.shape({
    id: PropTypes.number,
    url: PropTypes.string,
    caption: PropTypes.string
  }),
  photographs_attributes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      url: PropTypes.string,
      caption: PropTypes.string
    })
  ),
  birth_date: PropTypes.string,
  size_id: PropTypes.number,
  color_id: PropTypes.number,
  coat: PropTypes.string,
  dog_registration_numbers_attributes: PropTypes.arrayOf(
    PropTypes.shape({
      registration_number_attributes: PropTypes.shape({
        value: PropTypes.string
      })
    })
  ),
  microchip_number: PropTypes.string,
  dog_awards_attributes: PropTypes.arrayOf(
    PropTypes.shape({
      award_attributes: PropTypes.shape({
        name: PropTypes.string,
        breeder_profile_id: PropTypes.number
      })
    })
  ),
  sire: PropTypes.string,
  dam: PropTypes.string
};

export const BreederProfilePropTypes = {
  cropped_photo_url: PropTypes.string,
  delivery_methods_availability_list: PropTypes.string,
  facility_location: PropTypes.string,
  id: PropTypes.number,
  name: PropTypes.string,
  profile_photo_url: PropTypes.string,
  puppies: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      profile_photo_url: PropTypes.string,
      url: PropTypes.string
    })
  ),
  result_photos: PropTypes.array,
  url: PropTypes.string,
  vetted: PropTypes.bool,
  breeds: PropTypes.array,
  has_delivery: PropTypes.bool,
  sleep_mode: PropTypes.bool,
  preferred_qualification: PropTypes.shape({
    status: PropTypes.string
  })
};

export const BreederProfileInterviewPropTypes = {
  instagram_url: PropTypes.string,
  facebook_url: PropTypes.string,
  breeder_full_name: PropTypes.string,
  vetted: PropTypes.bool,
  formatted_certifications: PropTypes.string,
  formatted_clubs: PropTypes.string,
  photo: PropTypes.shape({
    url: PropTypes.string
  }).isRequired,
  answers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      question: PropTypes.string,
      answer: PropTypes.string
    })
  ).isRequired
};

export const WaitlistPreferencePropTypes = {
  id: PropTypes.number,
  breed_id: PropTypes.number,
  preference_type: PropTypes.string,
  description: PropTypes.string
};

export const BreederProfileLitterPropTypes = {
  id: PropTypes.number,
  state: PropTypes.string,
  breed_name: PropTypes.string,
  go_home_date: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date)
  ]),
  birth_date: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date)
  ]),
  name: PropTypes.string,
  parents: PropTypes.arrayOf(PropTypes.shape(DogPropTypes)),
  puppies: PropTypes.arrayOf(
    PropTypes.shape({
      ...DogPropTypes,
      available: PropTypes.bool
    })
  ),
  sizes: PropTypes.string,
  birth_date_month_year: PropTypes.string,
  ready_date_month_year: PropTypes.string,
  status: PropTypes.string,
  overview_header: PropTypes.string
};

export const BreederProfileDeliverMethodPropTypes = {
  facility: PropTypes.shape({
    latitude: PropTypes.number,
    longitude: PropTypes.number
  }),
  methods: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      description: PropTypes.string
    })
  )
};

export const BreederProfilePuppiesPropTypes = {
  id: PropTypes.number.isRequired,
  breed_name: PropTypes.string.isRequired,
  gender: PropTypes.string,
  litter_id: PropTypes.number.isRequired,
  name: PropTypes.string,
  profile_photo_id: PropTypes.number,
  profile_photo_url: PropTypes.string,
  ready_date: PropTypes.string,
  reserved: PropTypes.bool.isRequired
};

export const PhotographPropTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  type: PropTypes.string,
  url: PropTypes.string,
  caption: PropTypes.string,
  media_category_id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  photographable_id: PropTypes.number,
  photographable_type: PropTypes.string
};

export const MediaFilePropTypes = {
  type: PropTypes.oneOf(['Photograph', 'Video']),
  playback_ids: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.string })),
  analytics: PropTypes.shape({
    view: PropTypes.string.isRequired,
    view_feature: PropTypes.string.isRequired,
    view_feature_location: PropTypes.string.isRequired
  }),
  specificImageProps: PropTypes.shape({
    className: PropTypes.string
  }),
  alt: PropTypes.string,
  href: PropTypes.string,
  url: PropTypes.string,
  urls: PropTypes.arrayOf(PropTypes.string)
};

export const LitterPhotographPropTypes = {
  ...PhotographPropTypes,
  puppy: PropTypes.shape({
    ...DogPropTypes,
    available: PropTypes.bool
  })
};

export const BreederProfilePhotographPropTypes = {
  ...PhotographPropTypes,
  category: PropTypes.string.isRequired
};

export const BreedSearchResultPropTypes = {
  id: PropTypes.number,
  url: PropTypes.string,
  name: PropTypes.string
};

export const BreederProfileBasicPropTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  years_of_experience: PropTypes.number,
  instagram_url: PropTypes.string,
  facebook_url: PropTypes.string,
  profile_photo_url: PropTypes.string,
  user: PropTypes.shape({
    email: PropTypes.string,
    id: PropTypes.number,
    last_name: PropTypes.string,
    first_name: PropTypes.string
  }).isRequired
};

// The next three prop type declarations altogether compose
// the payload of the breeder_profiles/<id>/edit.json endpoint
export const BreederProfileEditBreederProfilePropTypes = {
  breeder_breeds_attributes: PropTypes.arrayOf(
    PropTypes.shape({
      breed_id: PropTypes.number.isRequired,
      breed_name: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired
    })
  ).isRequired,
  breeder_profile_certifications_attributes: PropTypes.array.isRequired,
  local_breeder_profile_clubs_attributes: PropTypes.array.isRequired,
  national_breeder_profile_clubs_attributes: PropTypes.array.isRequired,
  established_on: PropTypes.string,
  facebook_url: PropTypes.string,
  facility_attributes: PropTypes.shape({
    address_attributes: PropTypes.shape({
      city: PropTypes.string,
      id: PropTypes.number,
      postal_code: PropTypes.string,
      state_id: PropTypes.number,
      street_line_one: PropTypes.string,
      street_line_two: PropTypes.string
    }),
    id: PropTypes.number
  }),
  id: PropTypes.number,
  instagram_url: PropTypes.string,
  name: PropTypes.string,
  profile_photo: PropTypes.shape({
    crop_max_x: PropTypes.number,
    crop_max_y: PropTypes.number,
    crop_min_x: PropTypes.number,
    crop_min_y: PropTypes.number,
    id: PropTypes.number,
    url: PropTypes.string
  }),
  short_description: PropTypes.string,
  specialty_description: PropTypes.string,
  user_attributes: PropTypes.shape({
    first_name: PropTypes.string,
    id: PropTypes.number,
    last_name: PropTypes.string
  })
};
export const BreederProfileEditAllPropTypes = {
  breeder_profile: PropTypes.shape(BreederProfileEditBreederProfilePropTypes),
  error_messages: PropTypes.array
};

export const CroppablePhotographPropTypes = {
  crop_min_x: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  crop_min_y: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  crop_max_x: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  crop_max_y: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  id: PropTypes.number,
  url: PropTypes.string,
  original_url: PropTypes.string,
  cropped_url: PropTypes.string
};

export const BreederProfileCoverPhotosPropTypes = {
  large: PropTypes.shape(CroppablePhotographPropTypes),
  medium: PropTypes.shape(CroppablePhotographPropTypes),
  small: PropTypes.shape(CroppablePhotographPropTypes)
};

export const BreedPropTypes = {
  cover_photo_url: PropTypes.string,
  id: PropTypes.number,
  name: PropTypes.string,
  slug: PropTypes.string,
  url: PropTypes.string
};

export const ShadowUserPropTypes = {
  id: PropTypes.number,
  name: PropTypes.string,
  profile_photo_url: PropTypes.string,
  short_address: PropTypes.string,
  long_address: PropTypes.string,
  facebook_url: PropTypes.string,
  initials: PropTypes.string,
  email: PropTypes.string,
  phone_number: PropTypes.string
};

export const FamilyPropTypes = {
  litter_preference: PropTypes.shape({
    name: PropTypes.string
  }),
  puppy_preference: PropTypes.shape({
    name: PropTypes.string,
    profile_photo_url: PropTypes.string,
    gender: PropTypes.string
  }),
  id: PropTypes.number,
  shadow_user: PropTypes.shape(ShadowUserPropTypes),
  preferences: PropTypes.string,
  delivery_preference: PropTypes.string,
  full_description: PropTypes.string,
  litter: PropTypes.shape({
    name: PropTypes.string,
    parents: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string
      })
    )
  }),
  dog: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    profile_photo_url: PropTypes.string
  }),
  payment_status: PropTypes.string,
  created_at: PropTypes.string,
  most_relevant_puppy_for_display: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    litter_id: PropTypes.number,
    formatted_ready_date: PropTypes.string,
    profile_photo_url: PropTypes.string
  })
};

export const MePropTypes = asTptConnectPropTypes({
  name: PropTypes.string.isRequired,
  user_name: PropTypes.string.isRequired,
  profile_url: PropTypes.string.isRequired,
  profile_photo_url: PropTypes.string.isRequired
});

export const HealthTestPropTypes = {
  description: PropTypes.string,
  health_test_category: PropTypes.shape({
    description: PropTypes.string,
    icon: PropTypes.string,
    id: PropTypes.number,
    name: PropTypes.string
  }),
  id: PropTypes.number,
  name: PropTypes.string,
  verified: PropTypes.bool
};

export const BreederProfileBreedPropTypes = {
  id: PropTypes.number,
  name: PropTypes.string,
  cover_photo_url: PropTypes.string
};

export const ShelterIndexPropTypes = {
  address: PropTypes.shape({
    lat: PropTypes.number,
    lng: PropTypes.number
  }),
  dogs_count: PropTypes.number,
  dog_photographs: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string,
      alt_text: PropTypes.string
    })
  ),
  id: PropTypes.number,
  name: PropTypes.string,
  cover_photo_url: PropTypes.string,
  rating: PropTypes.number,
  short_description: PropTypes.string,
  full_address: PropTypes.string
};

export const ShelterShowPropTypes = {
  charity_navigator_rating: PropTypes.number,
  dogs: PropTypes.arrayOf(
    PropTypes.shape({
      age_description: PropTypes.string,
      gender: PropTypes.string,
      size_description: PropTypes.string,
      photographs: PropTypes.arrayOf(
        PropTypes.shape({
          alt_text: PropTypes.string,
          url: PropTypes.string
        })
      ).isRequired
    })
  ).isRequired,
  facebook_url: PropTypes.string,
  full_address: PropTypes.string,
  short_address: PropTypes.string,
  id: PropTypes.number,
  instagram_url: PropTypes.string,
  long_description: PropTypes.string,
  name: PropTypes.string,
  operating_hours: PropTypes.object,
  phone_number: PropTypes.string,
  cover_photo_url: PropTypes.string,
  rating: PropTypes.number,
  rating_url: PropTypes.string,
  website_url: PropTypes.string
};

export const ShelterDogPropTypes = {
  id: PropTypes.number,
  name: PropTypes.string,
  breed_name: PropTypes.string,
  gender: PropTypes.string,
  age_description: PropTypes.string,
  size_description: PropTypes.string,
  description: PropTypes.string,
  photographs: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      url: PropTypes.string,
      alt_text: PropTypes.string
    })
  )
};

export const BreedOptionsPropTypes = {
  value: PropTypes.shape({
    data: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        hybrid: PropTypes.bool
      })
    ).isRequired
  }).isRequired
};

export const DogOptionPropType = {
  id: PropTypes.number,
  name: PropTypes.string,
  gender: PropTypes.string,
  url: PropTypes.string,
  tbd: PropTypes.bool
};

export const DogOptionsPropTypes = {
  value: PropTypes.shape({
    data: PropTypes.shape({
      moms: PropTypes.arrayOf(PropTypes.shape(DogOptionPropType)).isRequired,
      dads: PropTypes.arrayOf(PropTypes.shape(DogOptionPropType)).isRequired
    }).isRequired
  }).isRequired
};

export const PreferenceOptionPropType = {
  id: PropTypes.number.isRequired,
  preference_type: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  breed_id: PropTypes.number
};

export const BreederWaitlistPreferenceOptionsPropTypes = {
  value: PropTypes.shape({
    data: PropTypes.shape({
      gender: PropTypes.arrayOf(PropTypes.shape(PreferenceOptionPropType))
        .isRequired,
      color: PropTypes.arrayOf(PropTypes.shape(PreferenceOptionPropType))
        .isRequired,
      size: PropTypes.arrayOf(PropTypes.shape(PreferenceOptionPropType))
        .isRequired,
      markings: PropTypes.arrayOf(PropTypes.shape(PreferenceOptionPropType))
        .isRequired
    }).isRequired
  }).isRequired
};

export const DeliveryMethodOptionPropType = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string,
  transport_type: PropTypes.string,
  icon: PropTypes.string
};

export const DeliveryMethodOptionsPropTypes = {
  value: PropTypes.shape({
    data: PropTypes.arrayOf(PropTypes.shape(DeliveryMethodOptionPropType))
      .isRequired
  }).isRequired
};

export const AirportOptionPropType = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired
};

export const AirportOptionsPropTypes = {
  value: PropTypes.shape({
    data: PropTypes.arrayOf(PropTypes.shape(AirportOptionPropType)).isRequired
  }).isRequired
};

export const HealthTestOptionPropType = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired
};

export const HealthTestOptionsPropTypes = {
  value: PropTypes.shape({
    data: PropTypes.arrayOf(PropTypes.shape(HealthTestOptionPropType))
      .isRequired
  }).isRequired
};

export const StateOptionsPropTypes = {
  value: PropTypes.shape({
    data: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired
      })
    ).isRequired
  }).isRequired
};

export const BreederDeliveryMethodOptionPropType = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string
};

export const BreederDeliveryMethodOptionsPropTypes = {
  value: PropTypes.shape({
    data: PropTypes.arrayOf(PropTypes.shape(DeliveryMethodOptionPropType))
      .isRequired
  }).isRequired
};

export const ColorPreferenceOptionPropTypes = {
  color: PropTypes.shape({
    description: PropTypes.string,
    details_description: PropTypes.string,
    id: PropTypes.number
  })
};

export const TemplatePropTypes = PropTypes.shape({
  id: PropTypes.number.isRequired,
  status: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
});

export const SignatureRequestPropTypes = PropTypes.shape({
  id: PropTypes.number.isRequired,
  status: PropTypes.string.isRequired,
  created_at: PropTypes.string.isRequired,
  updated_at: PropTypes.string.isRequired,
  template: TemplatePropTypes
});

export const BreederWaitlistPrefPropTypes = PropTypes.shape({
  id: PropTypes.number,
  preference_type: PropTypes.string,
  description: PropTypes.string,
  breed_id: PropTypes.number
});

export const BreederLitterPrefPropTypes = PropTypes.shape({
  generation: PropTypes.arrayOf(BreederWaitlistPrefPropTypes),
  size: PropTypes.arrayOf(BreederWaitlistPrefPropTypes),
  color: PropTypes.arrayOf(BreederWaitlistPrefPropTypes),
  coat_type: PropTypes.arrayOf(BreederWaitlistPrefPropTypes)
});

export const WaitlistLitterPropTypes = PropTypes.shape({
  data: PropTypes.shape({
    litters: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        waitlist_members_count: PropTypes.number.isRequired
      })
    ),
    unassigned_waitlist_members_count: PropTypes.number.isRequired
  })
});

export const ContractTemplatePropTypes = PropTypes.shape({
  created_at: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  status: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
});

export const InitialInquiryPropTypes = {
  id: PropTypes.number,
  buyer_profile: PropTypes.shape({
    id: PropTypes.number,
    first_name: PropTypes.string,
    full_name: PropTypes.string,
    short_address: PropTypes.string,
    phone_number: PropTypes.string,
    profile_photo_url: PropTypes.string
  }),
  dog: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    gender: PropTypes.string,
    profile_photo_url: PropTypes.string,
    litter_name: PropTypes.string
  }),
  preferences: PropTypes.arrayOf(PropTypes.string)
};

export const PuppyCardPropTypes = {
  puppy: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    price: PropTypes.string,
    reserved: PropTypes.bool,
    puppy_card_description: PropTypes.string,
    profile_photo_id: PropTypes.number,
    profile_photo_url: PropTypes.string,
    distance_in_miles: PropTypes.number,
    litter_id: PropTypes.number,
    media_files: PropTypes.arrayOf(PropTypes.shape(PhotographPropTypes)),
    breeder_profile: PropTypes.shape({
      cropped_photo_url: PropTypes.string,
      display_apply_cta: PropTypes.bool,
      facility_location_state_abbreviation: PropTypes.string,
      // eslint-disable-next-line react/forbid-prop-types
      delivery_methods: PropTypes.object,
      delivery_methods_count: PropTypes.number,
      name: PropTypes.string,
      facility_location: PropTypes.string,
      profile_photo_url: PropTypes.string,
      url: PropTypes.string.isRequired,
      id: PropTypes.number,
      first_name: PropTypes.string,
      preferred_qualification: PropTypes.shape({
        status: PropTypes.string
      })
    }).isRequired
  }).isRequired,
  analytics: PropTypes.shape({
    page_number: PropTypes.number,
    position: PropTypes.number,
    view: PropTypes.string,
    view_feature_location: PropTypes.string
  }).isRequired,
  hideDistance: PropTypes.bool,
  showPrice: PropTypes.bool,
  trackInView: PropTypes.func,
  className: PropTypes.string,
  style: PropTypes.object, // eslint-disable-line react/forbid-prop-types,
  onClick: PropTypes.func,
  onFavoriteChange: PropTypes.func,
  view: PropTypes.string,
  bordered: PropTypes.bool,
  ctaType: PropTypes.oneOf(Object.values(PUPPY_CARD_CTA_TYPES))
};

export const PuppyCardPropDefaultTypes = {
  className: null,
  style: {},
  hideDistance: false,
  showPrice: false,
  trackInView: () => {},
  onClick: null,
  onFavoriteChange: null,
  view: '',
  bordered: false,
  ctaType: PUPPY_CARD_CTA_TYPES.NONE
};
